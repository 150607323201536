.main-wrapper {
    background-color: $white-clr;
}

.register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    max-width: 500px;
    margin: 2em auto 0;
    border-radius: 4px;
    padding: 1em;
    color: $dark_clr;

    &__title {
        margin: 1em 2em 2em;
    }

    &__form {
        width: 100%;

        &--inputgroup {
            margin-bottom: 30px;
            position: relative;

            input {
                width: 100%;
                padding: 10px;
                outline: 0;
                border: $border;
                font-size: 15px;
                border-radius: 0.2em;
                height: 3em;
                background-color: $bg_picture_clr;

                &:focus {
                    font-weight: bolder;
                    border-color: $bg_price_clr;
                }

                &:focus-within~label,
                &:not(:placeholder-shown)~label {
                    transform: translate(.5em, -1.5em);
                    font-size: .8em;
                    padding: 0.15em .5em;
                    color: $white_clr;
                    font-weight: 600;
                    background-color: $bg_price_clr;
                    text-transform: uppercase;
                }
            }

            label {
                @include label
            }

        }

        .link-password {
            text-align: right;
            font-size: .8em;
            margin-top: 0.5em;
        }

        &--error {
            font-size: .8em;
            background-color: rgba($bg_price_clr, 0.2);
            padding: 0.2em;
            color: $title_clr;
            text-align: center;
        }

        &--valid {
            width: 100%;
            padding: 14px;
            font-size: 1.1rem;
            font-weight: bold;
            margin-top: 2em;
            border: 0;
            border-radius: 10px;
            background-color: $bg_price_clr;
            color: $white_clr;
            cursor: pointer;

            &:hover {
                background-color: rgba($bg_price_clr, 0.8);
            }
        }

        $border-rad: 2rem;
        $clr-btn: #15DEA5;

        &--link {
            font-style: italic;
            font-weight: 600;
            margin: 2em 0;
        }
    }
}


.btn {
    width: 100%;
    padding: 14px;
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 2em;
    border: 0;
    border-radius: 10px;
    background-color: $bg_price_clr;
    color: $white_clr;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:hover {
        &::after {
            opacity: 1;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: blue;
        opacity: 0;
        z-index: -1;
        transition: opacity 300ms;
    }
}

.takeaway {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    margin: 1.5em;

    &__wrapper {
        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
        }
    }

    &__text {
        text-align: center;
    }

    &__text:nth-child(2) {
        transform: translateX(-10px);
    }
}