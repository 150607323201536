.about {
    display: grid;
    grid-template-columns: 1fr;

    &__title {
        @include title;
        @include banner_grid_column;
    }

    &__image-wrapper {  
        height: 400px;    
        margin-top: 1em;                     
        border-top: 5px solid $bg_price_clr;
        border-bottom: 5px solid $bg_price_clr; 
        overflow: hidden;
        position: relative; 

        & img {
            height: 100%;
            width: 100%;
            object-fit: fill;
            animation: facade 2s ease-out 0s both;           
        }      

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-color: rgba($title_clr, .2);
        }
    }

    &__introduction {
        margin: 1.5em 5rem;
        font-size: 1.2em;
        @include banner_grid_column;
    }

    @media screen and (max-width:$mobile-width) {
        &__title {
            margin: 1rem;
            font-size: 1.5rem;
        }

        &__image-wrapper {
            height: 200px;
        }

        &__introduction {
            margin: 1.5rem;
        }
    }
}

.about-activity {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    margin: 2.5em auto 0;
    max-width: 1000px;

    &__imagewrapper {
        width: 100%;
        height: 300px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px;
        }
        &--title {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            width: 100%;
            color: $white_clr;
            text-transform: uppercase;
            font-size:xx-large;
            z-index: 10;            
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0,21,43, .4);
          
        }
    }

    &__description:nth-child(3) {
        .cuts {
            list-style: inside;
            margin-top:  10px;
            &__wrapper {
                list-style-type: circle;
                line-height: 1.4;
                &--item {
                    font-weight: bolder;
                }
            }
        }
    }
    div[class*="about-activity__description"] > p {
        line-height: 1.4;
        font-size: 1em;
    }

     
    @media screen and (max-width: $mobile-width) {
        grid-template-columns: 1fr;
        row-gap: .5em;  
        column-gap: 0; 
        margin: 0 0.5em;
        &__imagewrapper { 
            &::after {
                margin: 0;
            }
        }          
        &__imagewrapper:nth-child(4) {           
            grid-row: 3;           
        }  
        div[class*="about-activity__description"] {
            margin: 0 0 2em;
        }    
    }
}




@keyframes facade {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.03);
    }
}