* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

$title_clr: #00152b;
$categ_clr: #74798c;
$bg_price_clr: #a90402;
$price_clr: #ebe8e8;
$white_clr: #fff;
$bg_clr: #00152b;
$bg_picture_clr: #f5fbfd;
$gray_clr1: rgb(0 0 0 /20%);
$gray_clr2: #f7f7f7;
$gray_clr3: #e8e8e1;
$gray_clr4: #6a6a6a;
$dark_clr: #1b2734;
$dark_clr2: #1c1d1d;
$banner_title_clr: #771818;
$blue_clr: #1970d2;

// optionnel
$bg_picture_clr2: #faf2f2;

$header_bg_cl: #000b28;

$border: 1px Solid $gray_clr3;
$border-radius: 8px;

$mobile-width: 430px;

@mixin btn_back {
  width: 60px;
  background-color: rgba(#000000, 0.6);
  padding: 0px 5px;
  border-radius: 14px;
  color: $white_clr;
  line-height: 0em;
}

@mixin btn_shopping($color, $bg_color) {
  outline: 0;
  border: 0;
  padding: 14px 16px;
  border-radius: 6px;
  color: $color;
  background-color: $bg_color;
  font-family: "Itim";
  border: 2px solid $gray_clr3;
  cursor: pointer;
  font-size: 1.05em;

  &:hover {
    background-color: darken($bg_color, 5%);
  }
}

@mixin btn-slider($direction) {
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  #{$direction}: 20px;
  border: 0;
  background-color: transparent;
  z-index: 10;

  svg {
    width: 30px;
    height: 30px;
    color: $bg_price_clr;
    border-radius: 50%;
    background-color: $white_clr;
  }
}

@mixin btn_select_quantity($direction) {
  width: 100px;
  height: 100%;
  outline: 0;
  border: 0;
  font-size: 1.1em;
  border-top-#{$direction}-radius: $border-radius;
  border-bottom-#{$direction}-radius: $border-radius;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    border-top-#{$direction}-radius: $border-radius;
    border-bottom-#{$direction}-radius: $border-radius;
    background-color: rgb($bg_clr, 0.1);
    transition: opacity 200ms ease-in;
  }
}

@mixin title {
  text-align: center;
  margin: 1rem;
  font-size: 2rem;
}

@mixin profile_title_mobile_margin {
  margin-bottom: 1em;
  font-size: 1.7em;
}

@mixin banner_grid_column {
  grid-column: 1/3;
}

@mixin btn($color) {
  border: 0;
  outline: none;
  background-color: $color;
  color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

@mixin input_style($width) {
  width: $width;
  padding: 10px;
  outline: 0;
  border: 1px Solid #e8e8e1;
  font-size: 15px;
  border-radius: 0.2em;
  height: 3em;
  background-color: #f5fbfd;
}

@mixin admin_side_content_bg {
  background-color: $white_clr;
}

@mixin hozontal_line {
  border-bottom: 2px solid $gray_clr3;
  margin: 2rem 1em;
  padding-bottom: 1.25em;
}

@mixin focus-within-label {
  transform: translate(0.5em, -1.5em);
  font-size: 0.8em;
  padding: 0.15em 0.5em;
  color: $white_clr;
  font-weight: 600;
  background-color: $orange_gray_clr;
  text-transform: uppercase;
}

@mixin icon_update_delete($color) {
  background-color: $color;
  margin: 0 0.3em;
  font-size: 1.5em;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
}

@mixin label {
  position: absolute;
  top: 11px;
  left: 10px;
  cursor: text;
  transition: transform 200ms ease-in-out;
  font-size: 18px;
}

@mixin input_admin {
  width: 100%;
  padding: 10px;
  outline: 0;
  border: 2px Solid $gray_clr3;
  font-size: 15px;
  border-radius: 0.5em;
  height: 3em;
  background-color: #f5fbfd;
}

@mixin categories_card($direction, $angle, $cover) {
  position: relative;
  width: 300px;
  height: 250px;
  flex-grow: 1;
  flex-basis: 300px;
  cursor: pointer;
  opacity: 0;
  transform: translateY(3.5em);
  transition: opacity, transform 0.4s cubic-bezier(0.5, 0, 0, 1);

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--title {
    position: absolute;
    #{$direction}: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    color: $white_clr;
    text-transform: uppercase;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      #{$angle}deg,
      rgba($title_clr, 0.7) $cover,
      rgba($title_clr, 0.2) 0
    );
  }
}

$link_admin_size: 10px;

$btn_edit_height: 3em;

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: Itim, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  @media screen and (max-width: $mobile-width) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    // overflow: auto;
  }
}
