.prd-info {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 3.7em  4.25em 1fr 5em 15em;
    column-gap: 2rem;
    align-items: center;
    max-width: 1300px;
    margin: 1.5em auto;
    height: 600px;
    padding: 0 1em;
    &__icon {
        grid-column-start: 1;
        grid-column-end: 4;
        align-self: flex-start;
        @include btn_back;
        
    }
    &__picture {
       height: 100%;
       grid-row-start: 2;
       grid-row-end: 6;
       grid-column-start: 1;
       grid-column-end: 2;
       background-color: $bg_picture_clr;
        &--image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }         
    &__title {
        grid-column-start: 2;
        grid-column-end: 4; 
        align-self: start;    
    }
    &__description {
        grid-column-start: 2;
        grid-column-end: 4;    
        height: 12rem;        
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 3px;
        padding: 0.5em;
        border-radius: 8px;       
    }
    &__price {
        background-color: $bg_price_clr;      
        text-align: center;
        padding: 11px;
        font-weight: 700;
        color: $white_clr;
    }
    &__quantity {
       align-self: end;
       padding: 1em;   
        border-left: 5px solid #1976D2;
        border-radius: 6px;
        color: #1d1A1A;
        font-weight: 700; 
    }
    &__count {        
        display: inline-flex;
        align-items: center;
        width: 170px;
        height: 50px;
        border: $border;
        border-radius: 8px;
        background-color: $bg_picture_clr;
        &--btnleft {  
            @include btn_select_quantity(left);         
            border-right: $border;
        }
        &--btnright {
            @include btn_select_quantity(right);      
            border-left: $border;        
        }
        &--number {
            width: 100%;
            text-align: center; 
            font-weight: 600;          
        }      
    }
    &__cart {
        background-color: $bg_clr;
        border-radius: 8px;        
        padding: 11px;
        font-weight: 700;
        color: $white_clr;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        &:hover {
            background-color: lighten($bg_clr, 8%);
        }
    }  
    @media screen and (max-width:$mobile-width) { 
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2em 1fr 4em 1fr 3em 3em; 
        gap: 0.7em; 
        height: inherit;
        &__icon {
            grid-column: 1/3;
        }
        &__picture {
            grid-row: inherit;
            grid-column: 1/3;
            height: 200px;
        }
        &__title {
            grid-column: 1/3;
            font-size: 18px;
            align-self: center;
        }
        &__description {
            grid-column: 1/3;          
            height: 100%;        
        }
        &__count {
            height: 40px;            
        } 
        &__cart {
            font-size: 14px;
        }             
    }  
}