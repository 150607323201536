.admin-products {
    @include admin_side_content_bg;
    overflow-y: scroll;

    &__title {
        @include hozontal_line;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        // margin: 2rem 1em;
        @include hozontal_line;

        input {
            @include input_style(50%);

            &:focus-within {
                border: 2px solid $bg_price_clr;
            }

        }


        button {
            @include btn($blue_clr);
        }
    }

    &__products {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        justify-items: center;
        max-width: 1200px;
        gap: 1em;
        margin: 1em auto;
    }
}



.add-product {
    @include admin_side_content_bg;

    &__title {
        @include hozontal_line;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    &__form {
        width: 70%;
        margin: 1em auto;

        &--inputgroup {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 1em 0;
            position: relative;

            label {
                color: $bg_price_clr;
                text-transform: uppercase
            }

            & input {
                @include input_admin;

                &:focus-within {
                    border-color: $bg_price_clr;
                }
            }

            label[for="image"] {
                position: absolute;
                top: 12px;
                z-index: 3;
                left: 12px;
                font-size: 0.8em;
                padding: 0.3em 0.5em;
                color: $white_clr;
                font-weight: 600;
                background-color: $bg_price_clr;
                text-transform: uppercase;
            }

            input[type="file"] {
                color: #9b9292;
            }



            textArea {
                @include input_admin;
                height: 130px;

                &:focus-within {
                    border-color: $bg_price_clr;
                }
            }

            .select {
                text-align: left;
                cursor: pointer;
                width: 100%;
                // color: #9b9292;

                &__icon {
                    position: absolute;
                    top: 45px;
                    right: 10px;
                    width: 24px;
                }
            }

            .select-content {
                z-index: 10;
                position: absolute;
                top: 4.63em;
                left: 0;
                right: 0;
                border: 2px solid $bg_price_clr;
                background-color: $bg_price_clr;
                color: $white_clr;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                animation: select-display .2s ease;

                &__item {
                    padding: .3em .5em;
                    cursor: pointer;
                    text-transform: capitalize;
                    transition: color .1s ease;

                    &:hover {
                        color: $title_clr
                    }
                }
            }
        }




        &--btn {
            display: flex;
            gap: 10px;
            margin-top: 2em;

            button[type="submit"] {
                @include btn($blue_clr);
            }

            button {
                @include btn($gray_clr4);
            }
        }

    }

}

@keyframes select-display {
    from {

        transform: scaleY(0);
        opacity: 0;
    }

    to {
        transform: scaleY(100%);
        opacity: 1;
    }
}