.contact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 2em;
    gap: 2em;
    &__infos {
        &--title {
            font-size: 2em;
        }
        &--title2 {
            font-size: 2em;
            text-transform: uppercase;
            margin: 1.2em 0;
        }
        .item {
            margin: 1.2em 0;
        }
    }
    &__cb {
        grid-column:  1/3;
        &--image {
            display: flex;
            justify-content: space-between;
            margin: 1em;
            .icon {
                width: 200px;
                height: 100px;
                &__item {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

        }
    }
    @media screen and (max-width:$mobile-width) {
        grid-template-columns: 1fr;
        margin: .5em;
        gap: inherit;
        &__infos {
            &--title {
                margin-top: 1em;
            }
        }
        &__cb {
            grid-column: inherit;

            &--image {
                margin: 1em .5em .5em .5em;
                .icon {
                    width: 70px;
                    height: 30px; 
                }
            }
        }
    }
}