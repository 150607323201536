.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: hidden;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: rgba(0, 0, 0, .6);

    &__search {
        width: 100%;
        height: 5em;
        min-width: 200px;
        transform-origin: top;
        animation: search-display .2s ease-in;

        &--content {
            background-color: $white_clr;
            display: inline-flex;
            align-items: center;
            gap: 1em;
            height: 100%;
            width: 100%;
            padding: 0 3.5em;

            .close-search {
                display: block;
                width: 30px;
                height: 30px;
                border: 1px solid $bg_price_clr;
                color: $white_clr ;
                background-color: $bg_price_clr ;
                border-radius: 50%;
                font-size: 1.5em;
                padding-inline: 0;
                cursor: pointer;
                text-align: center;
            }
        }


        &--input {
            height: 100%;
            width: 100%;
            border: 0;
            outline: 0;
            font-size: 1.1em;

        }

        @media screen and (max-width:$mobile-width) {
            .close-search {
                height: 21px;
                font-size: 17px;

            }
        }

    }

    &__content {
        flex: 1;
    }


}

@keyframes search-display {
    from {

        transform: scaleY(0);
        opacity: 0;
    }

    to {
        transform: scaleY(100%);
        opacity: 1;
    }
}