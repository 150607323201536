.header {
    display: grid;
    grid-template-columns: 12em 1fr;
    justify-items: center;
    align-items: center;
    column-gap: 2em;
    padding: 0 5rem;
    background-color: $bg_clr;
    color: $white_clr;
    box-shadow: 0 3px 4px rgb(0 0 0 / 30%);

    &__logo {
        &--debouche {
            height: 85px;
            width: 150px;
            object-fit: cover;
        }
    }



    &__nav {
        justify-self: end;
        display: flex;
        justify-content: stretch;

        &--item {
            margin: .8em;
            text-transform: uppercase;
            font-size: 1em;
            cursor: pointer;

            .icon {
                font-size: 1.9rem;
            }
        }

        .profile {
            width: 50px;
            height: 30px;
            line-height: 30px;
            font-style: italic;
            position: relative;
            background-color: $bg_price_clr;
            border-radius: 10px;

            &__name {
                width: 100%;
                height: 30px;
                color: $white_clr;
                outline: 0;
                border: 0;
                cursor: pointer;
                border-radius: 10px;
                background-color: transparent;
                padding-left: 6px;
                text-align: left;
                font-family: 'Roboto';
                font-weight: bolder;
            }

            &__icon {
                position: absolute;
                right: 0px;
                top: 3px;
                pointer-events: none;
            }

            &__dropdown {
                width: max-content;
                background-color: $title_clr;
                padding: 10px 20px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                margin-top: 2.02em;
                position: absolute;
                z-index: 20;
                animation: dropdown-display .2s ease-out;

                &--name {
                    cursor: default;
                    font-style: initial;
                    position: relative;
                    padding-bottom: 1em;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-bottom: 1px solid;
                        transform: translate(0, -10px);
                    }
                }

                &--item {
                    text-transform: capitalize;
                    width: 100%;
                    transition: color ease-in-out .2s;

                    &:hover {
                        color: $bg_price_clr;
                    }
                }


            }
        }
    }



    @media screen and (max-width:$mobile-width) {
        grid-template-columns: 8em 1fr;
        padding: 0.2em;

        &__logo {
            &--debouche {
                width: 130px;
                height: 76px;
            }
        }

        .profile {
            &__dropdown {
                margin-top: 2em;
            }
        }
    }

    @keyframes dropdown-display {
        from {
            transform: scaleY(0);
            opacity: 0;
        }

        to {
            transform: scaleY(100%);
            opacity: 1;
        }
    }
}



.sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10
}

.banner {
    position: relative;
    margin-bottom: 2em;
    height: 415px;

    &__wrapper {
        height: 100%;
        width: 100%;

        &--picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        color: $white_clr;
        z-index: 10;
        text-align: center;
        font-size: 4em;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba($title_clr, 0.4);
    }

    &__wrapper-btn {
        &--prev {
            @include btn-slider(left);
        }

        &--next {
            @include btn-slider(right)
        }
    }

    @media screen and (max-width:$mobile-width) {
        margin-bottom: .2em;
        height: 250px;

        &__wrapper {
            height: 250px;

            &--picture {
                height: 100%;
            }
        }

        &__title {
            font-size: 1.5em;
        }
    }
}


.filter {
    display: flex;
    max-width: 1200px;
    margin: 2.5em auto;
    gap: 1em;
    height: 30px;
    align-items: center;

    &__title {
        text-transform: uppercase;
        margin-left: 17px;
    }

    &__inputwrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &--item {
            input {
                appearance: none;
            }

            label {
                border: $border;
                padding: .3em;
                color: $bg_price_clr;
                cursor: pointer;
                font-size: 1.1em;
            }

            .active {
                background-color: $bg_price_clr;
                color: $white_clr;
            }
        }
    }

    &__button {
        outline: 0;
        margin-left: auto;
        border: 1px solid $bg_price_clr;
        padding: 4px 50px;
        border-radius: 8px;
        cursor: pointer;
        background-color: $white_clr;
        color: $bg_price_clr;
        font-family: "Itim", 'Roboto', sans-sherif;
        font-size: 17px;
        position: relative;
        z-index: 1;
        margin-right: 17px;

        &:hover {
            color: $white_clr;

            &::after {
                opacity: 1;
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background-color: $bg_price_clr;
            opacity: 0;
            transition: opacity .2s ease;
            border-radius: 7px;
        }
    }

    @media screen and (max-width:$mobile-width) {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        margin: 2.5em 0;
        height: inherit;
        gap: 2em;

        &__title {
            font-size: 1em;
        }

        &__button {
            display: block;
            grid-row: 3;
            margin-left: inherit;
            margin-right: inherit;
            padding: 4px 28px;
            font-size: 18px;
        }
    }
}

.pagination {
    display: flex;

    justify-content: center;
    align-items: center;
    margin: 2em auto;

    height: 3em;

    &__wrapper {
        margin-left: 1em;
        color: $bg_price_clr;
        display: flex;
        gap: 1em;
        align-items: center;

        &--arrow {
            font-size: 1.5em;
            cursor: pointer;
        }

        &--numbers {
            display: flex;
            gap: 0.5em;
            justify-content: center;

            .item {
                border: $border;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 22px;
                padding: 2px;
                border-radius: 2px;
                cursor: pointer;
            }
        }
    }


    @media screen and (max-width:$mobile-width) {
        margin: 1em 0;
    }
}

.products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    justify-items: center;
    max-width: 1200px;
    gap: 1em;
    margin: 1em auto;

    @media screen and (max-width:$mobile-width) {
        grid-template-columns: repeat(2, 1fr);
        padding: 0em;
        gap: .5em;
        margin: .5em 2% 1em;

        &__logo {
            &--debouche {
                width: 130px;
                height: 76px;
            }
        }
    }
}



.card {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 330px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 1em;
    row-gap: .9em;
    color: $title_clr;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px -3px;

    &__first-part {
        display: inline-block;

        &--picture {
            width: 230px;
            height: 10em;
            object-fit: contain;
            transform: scale(1);
            transition: transform ease-in-out 0.3s;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    &__title {
        align-self: flex-start;

    }

    &__category {
        align-self: flex-start;
        color: $categ_clr;
    }



    &__second-part {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        width: 100%;
        margin-top: auto;

        &--price {
            align-self: end;
            color: $white_clr;
            font-size: 1.1em;
            font-family: 'Itim', cursive;
            background-color: $bg_price_clr;
            padding: 0.3em;
        }

        &--cartshop {
            justify-self: end;
            border: 2px solid $bg_price_clr;
            padding: 0 .7em;
            border-radius: 5px;
            cursor: pointer;
        }

        &--update-delete {
            justify-self: end;
            color: $white_clr;

            .modify {
                @include icon_update_delete($blue_clr);
            }

            .delete {
                @include icon_update_delete($bg_price_clr);
            }

        }
    }

    @media screen and (max-width:$mobile-width) {
        width: 100%;
        height: 270px;
        padding: 0.5em;
        gap: .5em;
        box-shadow: rgba(0, 0, 0, 0.1) 1px 5px 15px 5px;

        &__first-part {
            &--picture {
                width: calc(139px + 2vw);
                height: 8em;
            }
        }

        &__title {
            font-size: 1em;
        }

        &__second-part {
            grid-template-columns: 1fr 4em;
            align-items: center;
            margin-top: auto;

            &--price {
                font-size: calc(10px + 1vw);
                height: 2em;
                line-height: 1.6;
                padding: 0.3em;
                width: fit-content;
            }

            &--cartshop {
                .icon {
                    width: 0.9em;
                    height: .9em;
                }
            }
        }
    }
}

.infos {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 35px;
    background-color: teal;
    color: $white_clr;
    padding-left: 20%; 
    &__icontel {
        margin-right:.5em;
       color: aqua;        
    } 
    @media screen and (max-width:$mobile-width) {
        height: 30px;
    }
}

.all-products-link {
    display: flex;
    justify-content: center;
    margin: 2.5em auto;

    &__item {
        border: 2px solid $title-clr;
        border-radius: 0.3em;
        font-size: 1.5em;
        padding: .5em 3em;
        font-weight: 600;
    }

    @media screen and (max-width:$mobile-width) {
        margin: 1em 0;

        &__item {
            font-weight: inherit;
            padding: .3em 3em;
        }
    }
}

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin: 2em 1em;   
    
    &__card-boucherie {
        @include categories_card(right, 235, 50%);
    }

    &__card-traiteur {
        @include categories_card(top, 0, 100%);

        &--title {
            width: 100%;
            text-align: center
        }
    }

    &__card-charcuterie {
        @include categories_card(left, -235, 50%);
    }

   

    @media screen and (max-width:$mobile-width) {
        margin: 0.5em;
    }
}

.active {
    opacity: 1;
    transform: translateY(0);
}



.article {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1200px;
    gap: 1em;
    margin: 1em auto;

    &__welcom {
        line-height: 1.5;

        em {
            font-weight: bold;
        }
    }

    &__title {
        grid-column: 1/3;
        justify-self: center;
        margin: 1em 0;
        text-transform: uppercase;
    }

    &__title2 {
        grid-column: 1/3;
        justify-self: center;
        margin: 1em 0;
        color: $gray_clr4;
    }

    &__content {
        height: 400px;
        width: 100%;


        &--picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__description {
        font-size: 1.2em;
        line-height: 1.5;
    }

    @media screen and (max-width:$mobile-width) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em;

        &__title {
            font-size: 1.2em;
        }

        &__welcom {
            text-align: justify;
        }

        &__content {
            display: none;
        }

        &__description {
            font-size: inherit;
            text-align: justify;
        }
    }
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // height: 10rem;
    background-color: #1c293c;
    color: $price_clr;
    padding: 2rem 5rem;
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 3rem;

    &__wrapper {
        &--item {
            padding-bottom: 11px;
            margin: 0.5em 0;
            position: relative;
            z-index: 1;

            &:hover {
                &::after {
                    opacity: 1;
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: 3px solid $price_clr;
                padding-bottom: 8px;
                z-index: -1;
                opacity: 0;
                transition: opacity 250ms ease-in-out;
            }
        }

        &--copyright {
            align-self: center;
            margin-top: 1.5em;
            font-size: .5em;
        }
    }

    @media screen and (max-width:$mobile-width) {
        padding: 2rem 1.5rem;
        font-size: 1.3rem;
        margin-top: auto;
    }
}


@keyframes slide-categories {
    0% {
        opacity: 0.5;  
        transform: scale(0.95) translateY(4em);  
           
    } 60% {       
        opacity: 1;  
    } 100% {
        transform: scale(1) translateY(0) ;     
    }   
}