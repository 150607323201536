.side-bar {
    display: flex;
    grid-template-columns: 1fr;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    color: $white_clr;

    &__logo {
        display: inline-flex;
        align-items: center;
        background-color:$title_clr;
        text-transform: uppercase;
        margin-bottom: 5.5em;
        height: 13em;
        img {           
            height: 130px;
            object-fit: fill;           
            padding: 10px;
            border-radius: 5px;
        }
        h1 {
            padding-top: 18px;
            font-size: 26px;
        }
    }

    &__link {
        display: flex;
        padding-left: .5em;
        align-items: center;
        height: 1.5em;
        gap: 5px;
        font-size: 1.5em;
        margin: 0 0 1em .5em;

        &:nth-child(7) {
            background-color: rgba(0,0,0,0.7);
            border-top-left-radius: $link_admin_size;
            border-bottom-left-radius: $link_admin_size;
            margin-top: auto;
            margin-bottom: 2.5em;
            padding: 1em;
        }      
    }

    .nav-active {
        color: $bg_price_clr;
        background-color: $white_clr ;
        border-top-left-radius: $link_admin_size;
        border-bottom-left-radius: $link_admin_size;
    }
}