.privacy, .sale {
  margin: 0 5rem;
  &__title {
    @include title;
  }
  &__subtitle {
    margin: 1rem 0;
  } 
  @media screen and (max-width:$mobile-width) {
    &__title {
      margin: 1.5rem 0;
      font-size: 1.2rem;
      text-transform: uppercase;
    }
    margin: 1rem;
    &__subtitle {
      &--law {
        font-size: 1rem;
      }
    }
  }
}