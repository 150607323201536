.cart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2em auto;
    gap: 2em;

    &__title {
        text-align: center;
        background-color: #f5fbfd;
        padding: 1em;
        margin-bottom: 1.2em;
    }

    &__shop {
        display: grid;
        grid-template-columns: min-content 1fr 8em 5em 4em;
        grid-template-rows: 1fr;
        justify-items: center;
        height: 5rem;
        align-items: center;
        border-bottom: 1px solid $gray_clr3;
        margin: 1em;

        &--picture {
            width: 125px;
            height: 5em;

            img {
                width: 100%;
                height: 95%;
                object-fit: cover;
            }
        }

        &--title {
            justify-self: flex-start;
            margin: 0 1em;
            min-width: 150px
        }

        &--delete {
            color: red;
            cursor: pointer;
        }
    }

    .cart-total__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 190px;
        border-radius: 4px;
        border: 1px solid #e8e8e1;

        &--total {
            padding: 1em;
            background-color: #f0f0f0;
            margin-bottom: auto;
        }

        &--checkout {
            padding: 14px 16px;
            color: $white_clr;
            background-color: $bg_clr;
            font-family: "Itim";
            border: $border;
            font-size: 1.05em;
            display: flex;
            justify-content: space-between;
        }

        &--process {
            display: flex;
            justify-content: space-between;

            .back {
                @include btn_shopping($bg_clr, $white_clr)
            }

            .shop {
                @include btn_shopping($white_clr, $bg_price_clr)
            }
        }
    }

    &__back {
        display: inline-flex;
        margin: 1em;
        cursor: pointer;
        color: $gray_clr4;

        span {
            margin-left: .5em;
        }
    }

    @media screen and (max-width:$mobile-width) {
        flex-direction: column;
        margin: 0em auto;

        &__shop {
            grid-template-columns: min-content 1fr 3.2em 1.5em;
            grid-template-rows: repeat(2, 1fr);
            height: 5rem;
            margin: .2em;
            column-gap: .4em;

            &--picture,
            &--total,
            &--delet {
                grid-row: 1/3;
            }

            &--title {
                margin: 0;
                font-size: 12px;
            }
        }

        &__count {
            grid-row: 2;
            grid-column: 2;
        }

        .prd-info__count {
            height: 30px;
            grid-row: 2;
            grid-column: 2;
            justify-self: flex-start;
        }

        .cart-total__wrapper {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: inherit;

            &--total {
                display: none;
            }
        }
    }
}