.admin-dashbord {
  h1 {
    margin: 1em 0.5em 0;
    border-bottom: 2px solid $gray_clr3;
    padding-bottom: 1em;
  }
  &__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1em;
    margin: 2em 4em;
    &--card {
      width: 100%;
      height: 200px;
      flex-basis: 250px;
      flex-grow: 1;
      box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      align-items: center;
      padding: 0.8em;

      .number {
        border: inherit;
        align-self: end;
        padding-bottom: inherit;
      }
      .title {
        align-self: baseline;
        color: $categ_clr;
        font-size: 1.3rem;
        text-transform: capitalize;
      }
      .icon {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        justify-self: center;
        border: 1px solid $gray_clr3;
        padding: 16px;
        border-radius: 5px;
      }
    }
  }
  &__view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 2em 4em;
    height: 400px;
    gap: 2em;

    &--orders {
      box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
      h2 {
        padding: 1em;
        background-color: #f5fbfd;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 2em;
      }
      th,
      td {
        text-align: left;
        padding: 8px;
      }
    }
  }
}
