.profile-infos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 2rem;
  height: 400px;
  margin: 1em 10%;

  &__title {
    justify-self: center;
    font-size: 2em;
    margin-bottom: 2em;
    grid-column: 1/3;
  }

  &__wrapper {
    h2 {
      margin-bottom: 0.5em;
    }

    &--item {
      padding: 0.3em;
    }

    &:nth-child(3) {
      grid-row: 3;
    }
  }

  &__edit {
    justify-self: end;
    align-self: end;
    grid-row: 3;
    grid-column: 2/3;

    button {
      @include btn($bg_price_clr);
    }
  }

  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr 6em;

    &__title {
      @include profile_title_mobile_margin;
    }
  }
}

.profile-edit {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 400px;
  max-width: 400px;
  margin: 1em auto;
  column-gap: 0.3em;

  &__title {
    grid-column: 1/3;
    justify-self: center;
    font-size: 2em;
  }

  &__inputwrapper {
    input {
      @include input_style(100%);

      &:focus {
        font-weight: bolder;
        border-color: $bg_price_clr;
      }
    }

    &:nth-child(6) {
      grid-column: 1/3;
    }
  }

  &__save {
    grid-column: 1;
    @include btn($bg_price_clr);
    height: $btn_edit_height;
  }

  &__cancel {
    @include btn($gray_clr4);
    height: $btn_edit_height;
  }

  @media screen and (max-width: 420px) {
    margin: 2em auto;

    &__title {
      @include profile_title_mobile_margin;
    }

    &__inputwrapper {
      grid-column: 1/3;
    }

    &__save,
    &__cancel {
      margin-top: 1.5em;
    }
  }
}