.admin {
    display: grid;
    grid-template-columns: 15em 1fr;
    background-color: $bg_price_clr ;
    height: 100vh;
    overflow: hidden
}


.admin-dashbord {
    @include admin_side_content_bg;
}

.admin-collection {
    @include admin_side_content_bg;
}



.admin-orders {
    @include admin_side_content_bg
}

.admin-customers {
    @include admin_side_content_bg
}

.admin-admins {
    @include admin_side_content_bg
}

.admin-product {
    @include admin_side_content_bg
}